<template>
  <div style="padding-right: 20px">
    <v-timeline align-top dense v-if="voucherData">
      <v-timeline-item
        v-for="(tracking, i) in voucherData.trackingHistory"
        :key="tracking._id"
        color="black"
        small
      >
        <div class="text-left accent timeline-box">
          <div class="font-size-16 font-weight-bold m-b-5" style="color: black">
            {{ tracking.title }}
          </div>
          <div>{{ tracking.subTitle }}</div>
        </div>
        <div
          v-if="i === 0"
          class="text-left radius-16 p-10 mt-4 font-weight-bold"
          style="background: #fff6e8"
        >
          <div class="p-10">
            <div class="m-b-10" style="font-size: 14px; color: black">
              #{{ voucherData._id }} - #{{
                voucherData.optimization.idBeforeOptim
              }}
            </div>
            <div class="font-weight-bold m-b-5">Στοιχεία Παραλαβής</div>
            <div class="m-b-5" style="color: black">
              <font-awesome-icon
                :icon="faStore"
                class="m-r-5"
              ></font-awesome-icon>
              {{ voucherData.originName }}
            </div>
          </div>
          <div class="p-10">
            <div class="font-weight-bold m-b-5 m-t-5">Στοιχεία Παράδοσης</div>
            <div class="m-b-5" style="color: black">
              <font-awesome-icon
                :icon="faUser"
                class="m-r-5"
              ></font-awesome-icon
              >{{ voucherData.clientName }}
            </div>
            <div class="m-b-5" style="color: black">
              <font-awesome-icon
                :icon="faPhone"
                class="m-r-5"
              ></font-awesome-icon
              >{{ voucherData.clientMobile }}
            </div>
            <div class="m-b-5" style="color: black">
              <font-awesome-icon
                :icon="faMapMarkerAlt"
                class="m-r-5"
              ></font-awesome-icon
              >{{ voucherData.clientAddress.text }}
            </div>
            <div class="m-b-5" style="color: black">
              <font-awesome-icon
                :icon="faComment"
                class="m-r-5"
              ></font-awesome-icon
              >{{ voucherData.clientExtraDetails }}
            </div>
            <div
              v-if="voucherData.paymentAmount"
              class="m-b-5"
              style="color: black"
            >
              <font-awesome-icon
                :icon="faEuroSign"
                class="m-r-5"
              ></font-awesome-icon>
              {{ voucherData.paymentAmount.toFixed(2) }}
            </div>
            <v-btn
              v-if="!voucherData.trackingActions.approvedDetails && !isDisabled"
              @click="approveInfo"
              width="100%"
              height="46"
              class="ma-2"
              color="success"
              style="
                text-transform: initial;
                display: flex;
                justify-content: space-between;
              "
            >
              <span class="font-size-16">Επιβεβαίωση Στοιχείων</span>
            </v-btn>
            <div v-else class="m-b-5" style="color: green">
              <font-awesome-icon
                :icon="faCheckCircle"
                class="m-r-5"
              ></font-awesome-icon>
              Επιβεβαιωμένα Στοιχεία
            </div>
          </div>
        </div>
        <div class="text-right m-t-5" style="font-style: italic">
          {{ getFullDate(tracking.timestamp) }}
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { getFullDateFromTimestamp } from "@/common-js/timeConverts";
import {
  faUser,
  faPhone,
  faMapMarkerAlt,
  faComment,
  faStore,
  faEuroSign,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { mapActions } from "vuex";
export default {
  props: {
    voucherData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      faUser,
      faPhone,
      faMapMarkerAlt,
      faComment,
      faStore,
      faEuroSign,
      faCheckCircle,
    };
  },
  computed: {
    isDisabled() {
      return this.voucherData.completed || this.voucherData.cancelled;
    },
  },
  methods: {
    ...mapActions({
      confirmDetails: "livetracking/livetracking/confirmDetails",
    }),
    getFullDate(timestamp) {
      return getFullDateFromTimestamp(new Date(timestamp));
    },
    approveInfo() {
      this.confirmDetails({
        vm: this,
        id: this.voucherData._id,
      });
    },
  },
};
</script>

<style lang="scss">
.timeline-box {
  border-radius: 0 16px 16px 16px;
  padding: 20px;
}
</style>
